import { cn } from "@/lib/utils";
import Image from "next/image";

interface IProfileButtonProps {
  className?: string;
  isLargeScreen?: boolean;
  linkToPlatform?: string;
  avatarIconUrl?: string | null;
  email?: string | null;
}

const ProfileButton: React.FC<IProfileButtonProps> = ({
  className,
  isLargeScreen,
  linkToPlatform,
  avatarIconUrl,
  email,
}) => {
  return (
    <button
      aria-label="profile button"
      className={cn(
        "lg:bg-black-5 flex outline-none focus-visible:outline-dijon lg:rounded-full rounded-0 bg-white h-8 w-8 lg:h-[36px] lg:w-[36px] justify-center items-center",
        className
      )}
      onClick={() => {
        linkToPlatform && (document.location = linkToPlatform);
      }}
    >
      {avatarIconUrl ? (
        <Image
          src={avatarIconUrl}
          width={isLargeScreen ? "20" : "32"}
          height={isLargeScreen ? "20" : "32"}
          alt={email ?? "profile image"}
          className="h-8 w-8 lg:h-[36px] lg:w-[36px] rounded-full"
        />
      ) : (
        <svg
          aria-label="profile icon"
          xmlns="http://www.w3.org/2000/svg"
          width={isLargeScreen ? "20" : "32"}
          height={isLargeScreen ? "20" : "32"}
          viewBox="0 0 20 20"
          fill="none"
        >
          <mask maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
            <rect width="20" height="20" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_3694_6433)">
            <path
              d="M9.99998 9.72274C9.17013 9.72274 8.47656 9.4441 7.91927 8.88682C7.36198 8.32953 7.08333 7.63596 7.08333 6.80611C7.08333 5.97625 7.36198 5.28268 7.91927 4.7254C8.47656 4.16811 9.17013 3.88947 9.99998 3.88947C10.8298 3.88947 11.5234 4.16811 12.0807 4.7254C12.638 5.28268 12.9166 5.97625 12.9166 6.80611C12.9166 7.63596 12.638 8.32953 12.0807 8.88682C11.5234 9.4441 10.8298 9.72274 9.99998 9.72274ZM14.9631 16.0897H5.03683C4.66243 16.0897 4.34394 15.9584 4.08137 15.6958C3.81879 15.4333 3.6875 15.1148 3.6875 14.7404V14.1747C3.6875 13.7527 3.80182 13.3713 4.03046 13.0305C4.25908 12.6897 4.55655 12.4326 4.92285 12.2592C5.7371 11.8834 6.57371 11.5946 7.43269 11.3927C8.29166 11.1907 9.14742 11.0898 9.99998 11.0898C10.8525 11.0898 11.7118 11.1907 12.5777 11.3927C13.4436 11.5946 14.2745 11.8836 15.0705 12.2596C15.4412 12.4327 15.7409 12.6897 15.9695 13.0305C16.1981 13.3713 16.3125 13.7527 16.3125 14.1747V14.7404C16.3125 15.1148 16.1812 15.4333 15.9186 15.6958C15.656 15.9584 15.3375 16.0897 14.9631 16.0897ZM5.02081 14.7564H14.9791V14.1693C14.9791 14.0041 14.9337 13.8566 14.8429 13.7268C14.7521 13.597 14.6298 13.5075 14.4759 13.4583C13.7858 13.1325 13.0537 12.8785 12.2797 12.6963C11.5058 12.5141 10.7458 12.4231 9.99998 12.4231C9.25412 12.4231 8.4942 12.5176 7.72023 12.7067C6.94626 12.8958 6.21419 13.1464 5.52402 13.4583C5.36878 13.534 5.24611 13.6302 5.156 13.7467C5.06587 13.8632 5.02081 14.0041 5.02081 14.1693V14.7564ZM9.99998 8.38945C10.4583 8.38945 10.8368 8.24014 11.1354 7.94153C11.434 7.64292 11.5833 7.26445 11.5833 6.80611C11.5833 6.34778 11.434 5.96931 11.1354 5.6707C10.8368 5.37208 10.4583 5.22278 9.99998 5.22278C9.54165 5.22278 9.16317 5.37208 8.86456 5.6707C8.56595 5.96931 8.41665 6.34778 8.41665 6.80611C8.41665 7.26445 8.56595 7.64292 8.86456 7.94153C9.16317 8.24014 9.54165 8.38945 9.99998 8.38945Z"
              fill="#051C2C"
            />
          </g>
        </svg>
      )}
    </button>
  );
};

export default ProfileButton;
